import { http } from '@/lib/http'
import {
  SourceListType,
  ContactListType,
  CounterpartyTagType,
  JournalSourceListType,
  ThirdPartyOpenAuthType,
  CounterpartyDetailType,
  TreasuryBalanceListType,
  EntityAccountDetailType,
  TradeCalculatePositionType
} from '#/AccountsTypes'

export default {
  /**
   * 批量删除Account
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {Array} data.entityAccountIds
   */
  deleteProjectAccount: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/batch/entity-account/delete`, {
      data
    })
  },
  /**
   * 获取Account列表
   * @param {string} entityId 主体id
   * @param {Object} params
   * @property {boolean} params.withDeleted 是否包含被删除账户
   */
  getEntityAccountList: (entityId: string, params?: object) => {
    return http.request('get', `/entity/${entityId}/entity-account`, { params })
  },
  /**
   * 获取最近余额
   * @param {string} entityId 主体id
   */
  getEntityTreasuryBalance: (entityId: string, params?: object) => {
    return http.request<ResponseData<TreasuryBalanceListType>>('get', `/entity/${entityId}/treasuryBalance`, { params })
  },
  /**
   * 获取衍生品持仓列表
   * @param {string} entityId 主体id
   */
  getTradeCalculatePosition: (entityId: string, data?: object) => {
    return http.request<ResponseData<TradeCalculatePositionType[]>>(
      'post',
      `/entity/${entityId}/trade/calculatePosition`,
      {
        data
      }
    )
  },
  /**
   * 获取余额对账项列表
   * @param {string} entityId 主体id
   * @param {Object} params
   * @property {string} params.currency
   * @property {string} params.entityAccountId
   * @property {number} params.page 页码
   * @property {number} params.limit 每页条数
   */
  getTreasuryBalanceCheckpointList: (entityId: string, params: object) => {
    return http.request<ResponseData<TreasuryBalanceListType>>(
      'get',
      `/entity/${entityId}/treasuryBalance/checkpoint`,
      {
        params
      }
    )
  },
  /**
   * 创建余额对账项
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.entityAccountId
   * @property {string} data.currency
   * @property {string} data.datetimeStr
   * @property {string} data.timezone
   * @property {string} data.balance
   */
  createTreasuryBalanceCheckpoint: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/treasuryBalance/checkpoint`, {
      data
    })
  },
  /**
   * 删除余额对账项
   * @param {string} entityId 主体id
   * @param {string} treasuryBalanceCheckpointId 余额对账项id
   */
  deleteTreasuryBalanceCheckpoint: (entityId: string, treasuryBalanceCheckpointId: string) => {
    return http.request('delete', `/entity/${entityId}/treasuryBalance/checkpoint/${treasuryBalanceCheckpointId}`)
  },
  /**
   * 执行余额对账
   * @param {string} entityId 主体id
   */
  executeTreasuryBalanceVerify: (entityId: string) => {
    return http.request('post', `/entity/${entityId}/treasuryBalance/verify`)
  },
  /**
   * 刷新所有余额
   * @param {string} entityId 主体id
   */
  refreshAllTreasuryBalance: (entityId: string) => {
    return http.request('post', `/entity/${entityId}/balance/bulkRefresh`)
  },
  /**
   * 添加创建Account
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.type WALLET | EXCHANGE | CSV
   * @property {string} data.name
   * @property {string} data.identity
   * @property {string} [data.platformId]
   * @property {string[]} [data.entityAccountTags]
   * @property {string} [data.memo]
   */
  createEntityAccount: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/entity-account`, {
      data
    })
  },
  /**
   * 获取Account详情
   * @param {string} entityId 主体id
   * @param {string} entityAccountId
   */
  getEntityAccountDetail: (entityId: string, entityAccountId: string) => {
    return http.request<ResponseData<EntityAccountDetailType>>(
      'get',
      `/entity/${entityId}/entity-account/${entityAccountId}`
    )
  },
  /**
   * 编辑Account
   * @param {string} entityId 主体id
   * @param {string} entityAccountId 主体id
   * @param {Object} data
   * @property {string} data.name
   * @property {string[]} [data.entityAccountTags]
   * @property {string} [data.memo]
   */
  editEntityAccount: (entityId: string, entityAccountId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/entity-account/${entityAccountId}`, {
      data
    })
  },
  /**
   * 批量导入Account
   * @param {string} entityId 主体id
   * @param {Object} data
   * @param {string} data.entityFileId
   */
  importEntityAccount: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/entity-account/import`, { data })
  },
  /**
   * 导入Account记录状态关闭
   * @param {string} entityId
   * @param {string} entityAccountCsvUpload
   */
  closeEntityAccountCsvUploadStatus: (entityId: string, entityAccountCsvUpload: string) => {
    return http.request(
      'put',
      `/entity/${entityId}/entity-account/entityAccountCsvUpload/${entityAccountCsvUpload}/disable`
    )
  },
  /**
   *  获取某provider的balance
   * @param entityId
   * @param entityAccountId
   * @param providerId
   * @returns
   */
  getEntityAccountProviderBalance: (entityId: string, entityAccountId: string, providerId: string) => {
    return http.request('get', `/entity/${entityId}/entity-account/${entityAccountId}/provider/${providerId}/balance`)
  },
  /**
   *  获取某provider的balance
   * @param entityId
   * @param entityAccountId
   * @param providerId
   * @returns
   */
  switchEntityAccountProvider: (entityId: string, entityAccountId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/entity-account/${entityAccountId}/usedProvider`, { data })
  },
  /**
   * 刷新Account余额
   * @param entityId
   * @param entityAccountId
   * @returns
   */
  refreshEntityAccountBalance: (entityId: string, entityAccountId: string) => {
    return http.request('post', `/entity/${entityId}/entity-account/${entityAccountId}/balance/refresh`)
  },
  /**
   * 获取交易来源列表
   * @param {string} entityId 主体id
   * @param {Object} params
   * @property {number} params.page 页码
   * @property {number} params.limit 每页条数
   */
  getAccountSourceList: (entityId: string, params: Object) => {
    return http.request<ResponseData<SourceListType>>('get', `/entity/${entityId}/source`, { params })
  },
  /**
   * 添加交易来源
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.name 交易来源名称
   * @property {string} data.type 交易来源类型 EXCHANGE/CSV
   * @property {string} [data.entityAccountId]
   * @property {string} [data.apiKey] api key
   * @property {string} [data.apiSecret] api secret
   * @property {string} [data.passphrase]
   * @property {string} [data.entityFileId] 项目文件id
   * @property {object} [data.transactionOption]
   * @property {transactionOption} data.transactionOption.operator
   * @property {transactionOption} data.transactionOption.value
   */
  addAccountSource: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/source`, { data })
  },
  /**
   * 修改交易来源
   * @param {string} entityId 主体id
   * @param {string} transactionSourceId 交易来源id
   * @param {Object} data
   * @property {string} data.type 交易来源类型 WALLET/EXCHANGE/CSV
   * @property {string} data.name 项目账户名称
   * @property {string} [data.apiKey] api key
   * @property {string} [data.apiSecret] api secret
   */
  editAccountSource: (entityId: string, transactionSourceId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/source/${transactionSourceId}`, { data })
  },
  /**
   * 删除交易来源
   * @param {string} entityId 主体id
   * @param {string} transactionSourceId 交易来源id
   */
  deleteAccountSource: (entityId: string, transactionSourceId: string) => {
    return http.request('post', `/entity/${entityId}/source/${transactionSourceId}/delete`)
  },
  /**
   * 获取交易来源详情
   * @param {string} entityId 主体id
   * @param {string} transactionSourceId 交易来源id
   */
  getAccountSourceDetail: (entityId: string, transactionSourceId: string) => {
    return http.request('get', `/entity/${entityId}/source/${transactionSourceId}`)
  },
  /**
   * 申请交易来源授权链接
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.type authType
   */
  applySourceAuth: (entityId: string, data: object) => {
    return http.request<ResponseData<ThirdPartyOpenAuthType>>('post', `/entity/${entityId}/third-party/open/auth`, {
      data
    })
  },
  /**
   * 获取交易来源授权详情
   * @param {string} entityId 主体id
   * @param {string} outsideOpenAuthDataId 交易来源授权id
   */
  getSourceAuthDetail: (entityId: string, outsideOpenAuthDataId: string) => {
    return http.request<ResponseData<ThirdPartyOpenAuthType>>(
      'get',
      `/entity/${entityId}/third-party/open/auth/${outsideOpenAuthDataId}`
    )
  },
  /**
   * 获取journal来源列表
   * @param {string} entityId 主体id
   * @param {Object} params
   * @property {number} params.page 页码
   * @property {number} params.limit 每页条数
   */
  getJournalSourceList: (entityId: string, params?: Object) => {
    return http.request<ResponseData<JournalSourceListType>>('get', `/entity/${entityId}/externalJournalSource`, {
      params
    })
  },
  /**
   * 编辑交易来源
   * @param {string} entityId 主体id
   * @param {string} externalJournalSourceId journal来源id
   * @param {Object} data
   * @property {string} data.name 项目账户名称
   */
  editJournalSource: (entityId: string, externalJournalSourceId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/externalJournalSource/${externalJournalSourceId}`, { data })
  },
  /**
   * 删除journal来源
   * @param {string} entityId 主体id
   * @param {string} externalJournalSourceId journal来源id
   */
  deleteJournalSource: (entityId: string, externalJournalSourceId: string) => {
    return http.request('delete', `/entity/${entityId}/externalJournalSource/${externalJournalSourceId}`)
  },
  /**
   * 获取联系人列表
   * @param {string} entityId 主体id
   * @param {object} params
   * @property {number} params.page 页码
   * @property {number} params.limit 每页条数
   */
  getContactUnNameList: (entityId: string, params: object) => {
    return http.request<ResponseData<ContactListType>>('get', `/entity/${entityId}/contact/unnamed`, { params })
  },
  /**
   * 获取counterpart列表
   * @param {string} entityId 主体id
   * @param {object} params
   * @property {number} [params.keywords] 关键字
   * @property {number} params.page 页码
   * @property {number} params.limit 每页条数
   */
  getCounterpartyList: (entityId: string, params: object) => {
    return http.request('get', `/entity/${entityId}/counterparty`, { params })
  },
  /**
   * 获取counterpart详情
   * @param {string} entityId 主体id
   * @param {string} counterpartyId 对手方id
   */
  getCounterpartDetail: (entityId: string, counterpartyId: string) => {
    return http.request<ResponseData<CounterpartyDetailType>>(
      'get',
      `/entity/${entityId}/counterparty/${counterpartyId}`
    )
  },
  /**
   * 搜索Counterpart标签
   * @param {string} entityId 主体id
   * @param {Object} params
   * @property {string} params.keywords
   */
  getCounterpartTag: (entityId: string, params: object) => {
    return http.request<ResponseData<CounterpartyTagType[]>>('get', `/entity/${entityId}/counterparty/tags`, { params })
  },
  /**
   * 添加Counterparty
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.name 对手方名称
   * @property {string} data.type 对手方类型
   * @property {Object} data.contact 平台id
   * @property {string} data.contact.identity 对手方身份 0x111
   * @property {string} data.contact.platformId 平台id
   * @property {Array} data.tags 对手方标签
   * @property {string} data.memo 对手方备注
   */
  addCounterparty: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/counterparty`, { data })
  },
  /**
   * 编辑Counterparty
   * @param {string} entityId 主体id
   * @param {string} counterpartyId 对手方id
   * @param {Object} data
   * @property {string} data.name 对手方名称
   * @property {string} data.type 对手方类型
   * @property {Object} data.contact 平台id
   * @property {string} data.contact.identity 对手方身份 0x111
   * @property {string} data.contact.platformId 平台id
   * @property {Array} data.tags 对手方标签
   * @property {string} data.memo 对手方备注
   */
  editCounterparty: (entityId: string, counterpartyId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/counterparty/${counterpartyId}`, { data })
  },
  /**
   * 删除Counterparty
   * @param {string} entityId 主体id
   * @param {string} counterpartyId 对手方id
   */
  deleteCounterparty: (entityId: string, counterpartyId: string) => {
    return http.request('delete', `/entity/${entityId}/counterparty/${counterpartyId}`)
  },
  /**
   * 批量删除Counterparty
   * @param {string} entityId 主体id
   * @param {Object} data
   * @param {string[]} data.counterpartyIds
   */
  deleteCounterpartyBatch: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/counterparty/bulkDelete`, { data })
  },
  /**
   * 导入Counterparty
   * @param {string} entityId 主体id
   * @param {Object} data
   * @param {string} data.entityFileId
   */
  importCounterparty: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/counterparty/import`, { data })
  },
  /**
   * 导入Counterparty记录状态关闭
   * @param {string} entityId
   * @param {string} counterpartyCsvUploadId
   */
  closeCounterpartyCsvUploadStatus: (entityId: string, counterpartyCsvUploadId: string) => {
    return http.request('put', `/entity/${entityId}/counterpartyCsvUpload/${counterpartyCsvUploadId}/disable`)
  },
  /**
   * 添加Counterparty的Account
   * @param {string} entityId 主体id
   * @param {string} counterpartyId 对手方id
   * @param {Object} data
   * @property {string} data.identity 对手方身份 0x111
   * @property {string} data.platformId 平台id
   */
  addCounterpartyContact: (entityId: string, counterpartyId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/counterparty/${counterpartyId}/contact`, { data })
  },
  /**
   * 编辑Counterparty的Account
   * @param {string} entityId 主体id
   * @param {string} counterpartyId 对手方id
   * @param {string} contactId 联系人id
   * @param {Object} data
   * @property {string} data.identity 对手方身份 0x111
   * @property {string} data.platformId 平台id
   */
  editCounterpartyContact: (entityId: string, counterpartyId: string, contactId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/counterparty/${counterpartyId}/contact/${contactId}`, { data })
  },
  /**
   * 删除Counterparty的Account
   * @param {string} entityId 主体id
   * @param {string} counterpartyId 对手方id
   * @param {string} contactId 联系人id
   */
  deleteCounterpartyContact: (entityId: string, counterpartyId: string, contactId: string) => {
    return http.request('delete', `/entity/${entityId}/counterparty/${counterpartyId}/contact/${contactId}`)
  },
  /**
  /**
   * 添加Counterparty的IdentificationCode
   * @param {string} entityId 主体id
   * @param {string} counterpartyId 对手方id
   * @param {Object} data
   * @property {string} data.name
   * @property {string} data.code
   */
  addCounterpartyIdentificationCode: (entityId: string, counterpartyId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/counterparty/${counterpartyId}/identification`, { data })
  },
  /**
   * 添加联系人到Counterparty
   * @param {string} entityId 主体id
   * @param {string} counterpartyId 对手方id
   * @param {Object} data
   * @property {string} data.platformId
   * @property {string} data.identity
   */
  addToCounterparty: (entityId: string, counterpartyId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/counterparty/${counterpartyId}/contact`, { data })
  },
  /**
   * 编辑Counterparty的IdentificationCode
   * @param {string} entityId 主体id
   * @param {string} counterpartyId 对手方id
   * @param {string} counterpartyIdentificationId
   * @param {Object} data
   * @property {string} data.name
   * @property {string} data.code
   */
  editCounterpartyIdentificationCode: (
    entityId: string,
    counterpartyId: string,
    counterpartyIdentificationId: string,
    data: object
  ) => {
    return http.request(
      'put',
      `/entity/${entityId}/counterparty/${counterpartyId}/identification/${counterpartyIdentificationId}`,
      { data }
    )
  },
  /**
   * 删除Counterparty的IdentificationCode
   * @param {string} entityId 主体id
   * @param {string} counterpartyId 对手方id
   * @param {string} counterpartyIdentificationId
   */
  deleteCounterpartyIdentificationCode: (
    entityId: string,
    counterpartyId: string,
    counterpartyIdentificationId: string
  ) => {
    return http.request(
      'delete',
      `/entity/${entityId}/counterparty/${counterpartyId}/identification/${counterpartyIdentificationId}`
    )
  },
  /**
   * 添加Contact
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.name 联系人名称
   * @property {string} data.role 联系人类型
   * @property {string} data.memo 联系人备注
   * @property {string} data.value 联系人卡号
   * @property {string} data.platformId 平台id
   */
  addContact: (entityId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/contact`, { data })
  },
  /**
   * 修改Contact
   * @param {string} entityId 主体id
   * @param {string} financialContactId 联系人id
   * @param {Object} data
   * @property {string} data.name 联系人名称
   * @property {string} data.role 联系人类型
   * @property {string} data.memo 联系人备注
   */
  editContact: (entityId: string, financialContactId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/contact/${financialContactId}`, { data })
  },
  /**
   * 删除Contact
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {Array} data.financialContactIds 联系人id
   */
  deleteContact: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/batch/contact/delete`, { data })
  },
  /**
   * 删除Account
   * @param {string} entityId 主体id
   * @param {string} entityAccountId 主体账户id
   */
  deleteAccount: (entityId: string, entityAccountId: string) => {
    return http.request('delete', `/entity/${entityId}/entity-account/${entityAccountId}`)
  },
  /**
   *检测账户是否可以删除
   * @param {string} entityId 主体id
   * @param {string} entityAccountId 主体账户id
   */
  checkCanBeDeleted: (entityId: string, entityAccountId: string) => {
    return http.request('post', `/entity/${entityId}/entity-account/${entityAccountId}/checkCanBeDeleted`)
  },

  /**
   * 批量导入到账户余额
   * @param {string} entityId 主体id
   * @param {Object} data
   * @param {string} data.entityFileId
   */
  importMultipleBalanceForAccount: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/treasuryBalance/bulk/import`, { data })
  },

  /**
   * 批量导入到账户余额记录状态关闭
   * @param {string} entityId
   * @param {string} entityFileCsvUploadId
   */
  closeBalanceAccountCsvUploadStatus: (entityId: string, entityFileCsvUploadId: string) => {
    return http.request('put', `/entity/${entityId}/entityFileCsvUpload/${entityFileCsvUploadId}/disable`)
  }
}
