import ReportsApi from '@/api/ReportsApi'
import AccountsApi from '@/api/AccountsApi'
import BusinessDataApi from '@/api/BusinessDataApi'
import {
  SourceListType,
  ContactListType,
  CounterpartyTagType,
  CounterpartyListType,
  JournalSourceListType,
  RecentlyBalanceParams,
  CounterpartyDetailType,
  TreasuryBalanceListType,
  EntityAccountDetailType,
  TradeCalculatePositionType
} from '#/AccountsTypes'
import { type GridApi, ColumnApi } from 'ag-grid-enterprise'
import { flatMap, sortBy, indexOf, forEach } from 'lodash-es'
import { BusinessDataSourceListType } from '#/BusinessDataTypes'

export const useAccountStore = defineStore({
  id: 'accountStore',
  state: () => {
    const accountState: {
      sourceList: SourceListType
      treasuryBalanceList: TreasuryBalanceListType
      tradeCalculatePositionList: TradeCalculatePositionType[]
      accountList: any[]
      accountDetail: EntityAccountDetailType | null
      contactList: ContactListType
      counterpartList: CounterpartyListType
      counterpartTagList: CounterpartyTagType[]
      counterpartyDetail: CounterpartyDetailType | null
      agGridApi: GridApi | null
      agGridColumnApi: ColumnApi | null
      isInitTreasuryBalance: boolean
      allSourceList: any
      journalSourceList: JournalSourceListType
      businessDataSourceList: BusinessDataSourceListType
      entityAccountUsedProviderMap: any
      datetime: string
      treasuryAccountTableLoading: boolean
      accountFilterList: any[]
      derivativeFilterList: any[]
    } = {
      sourceList: {
        total: 0,
        list: []
      },
      journalSourceList: {
        total: 0,
        list: []
      },
      businessDataSourceList: {
        total: 0,
        list: []
      },
      allSourceList: {
        total: 0,
        list: []
      },
      treasuryBalanceList: {
        list: [],
        total: {
          currencyList: [],
          totalFC: 0
        }
      },
      isInitTreasuryBalance: false,
      accountDetail: null,
      counterpartTagList: [],
      counterpartyDetail: null,
      contactList: {
        list: [],
        total: 0
      },
      accountList: [],
      tradeCalculatePositionList: [],
      counterpartList: {
        total: 0,
        list: []
      },
      agGridApi: null,
      agGridColumnApi: null,
      entityAccountUsedProviderMap: {},
      datetime: '',
      treasuryAccountTableLoading: true,
      accountFilterList: [],
      derivativeFilterList: []
    }
    return accountState
  },
  actions: {
    setDatetime(datetime: string) {
      this.datetime = datetime
    },
    setEntityAccountUsedProviderMap(entityAccountUsedProviderMap: any) {
      this.entityAccountUsedProviderMap = entityAccountUsedProviderMap
    },
    async switchEntityAccountProvider(entityId: string, entityAccountId: string, providerId: string) {
      try {
        const response = await AccountsApi.switchEntityAccountProvider(entityId, entityAccountId, { providerId })
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async refreshEntityAccountBalance(entityId: string, entityAccountId: string) {
      try {
        const response = await AccountsApi.refreshEntityAccountBalance(entityId, entityAccountId)
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getEntityAccountProviderBalance(entityId: string, entityAccountId: string, providerId: string) {
      try {
        const response = await AccountsApi.getEntityAccountProviderBalance(entityId, entityAccountId, providerId)
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取Source列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} page
     * @property {number} limit
     */
    async fetchAccountSourceList(entityId: string, params: object) {
      try {
        const response = await AccountsApi.getAccountSourceList(entityId, params)
        this.sourceList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取Journal Source列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} page
     * @property {number} limit
     */
    async fetchJournalSourceList(entityId: string, params: object) {
      try {
        const response = await AccountsApi.getJournalSourceList(entityId, params)
        this.journalSourceList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取业务数据Source列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} page
     * @property {number} limit
     */
    async fetchBusinessDataSourceList(entityId: string, params: object) {
      try {
        const response = await BusinessDataApi.getBusinessDataSourceList(entityId, params)
        this.businessDataSourceList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取全部类型Source列表
     * @param {string} entityId 主体id
     */
    async fetchEntitySourceList(entityId: string) {
      try {
        const response = await ReportsApi.getEntitySourceList(entityId)
        this.allSourceList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * @description: 设置Ag-Grid的Api
     * @param {GridApi} api
     * @param {ColumnApi} columnApi
     */
    setAgGridApi(api: GridApi, columnApi: ColumnApi) {
      this.agGridApi = api
      this.agGridColumnApi = columnApi
    },
    /**
     * 获取Account近期余额列表
     * @param {string} entityId 主体id
     * @param {RecentlyBalanceParams} params
     * @property {number} params.date 日期
     * @property {string} params.subGroup
     * @property {string} params.sort
     */
    async fetchEntityRecentlyBalanceList(entityId: string, params?: RecentlyBalanceParams) {
      try {
        const { data } = await AccountsApi.getEntityTreasuryBalance(entityId, params)
        const platformTypeList = ['CHAIN', 'EXCHANGE', 'BANK', 'CUSTODY', 'PAYMENT']
        this.treasuryBalanceList = data
        this.treasuryBalanceList.list = sortBy(data.list, (item) => indexOf(platformTypeList, item.platformType))
        this.isInitTreasuryBalance = false
        if (params?.subGroup === 'NONE') {
          forEach(this.treasuryBalanceList.list, (item: any) => {
            forEach(item.list, (balanceItem: any) => {
              this.entityAccountUsedProviderMap[balanceItem.entityAccount.entityAccountId] =
                balanceItem.entityAccount.usedProvider
            })
          })
        } else {
          forEach(this.treasuryBalanceList.list, (item: any) => {
            forEach(item.list, (secondLevelGroup: any) => {
              forEach(secondLevelGroup.list, (balanceItem: any) => {
                this.entityAccountUsedProviderMap[balanceItem.entityAccount.entityAccountId] =
                  balanceItem.entityAccount.usedProvider
              })
            })
          })
        }

        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取衍生品持仓列表
     * @param {string} entityId 主体id
     * @param {RecentlyBalanceParams} params
     * @property {number} params.date 日期
     * @property {string} params.subGroup
     * @property {string} params.sort
     */
    async fetchTradeCalculatePositionList(entityId: string, params?: RecentlyBalanceParams) {
      try {
        const { data } = await AccountsApi.getTradeCalculatePosition(entityId, params)
        // this.isInitTreasuryBalance = false
        this.tradeCalculatePositionList = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取Account详情
     * @param {string} entityId 主体id
     * @param {number} entityAccountId 主体id
     */
    async fetchEntityAccountDetail(entityId: string, entityAccountId: string) {
      try {
        const { data } = await AccountsApi.getEntityAccountDetail(entityId, entityAccountId)
        this.accountDetail = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取counterparty详情
     * @param {string} entityId 主体id
     * @param {number} counterpartyId 主体id
     */
    async fetchCounterpartyDetail(entityId: string, counterpartyId: string) {
      try {
        const { data } = await AccountsApi.getCounterpartDetail(entityId, counterpartyId)
        this.counterpartyDetail = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取联系人列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} params.page 页码
     * @property {number} params.limit 每页条数
     */
    async fetchContactList(entityId: string, params: object) {
      try {
        const { data } = await AccountsApi.getContactUnNameList(entityId, params)
        this.contactList = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取对手方列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} params.page 页码
     * @property {number} params.limit 每页条数
     */
    async fetchCounterpartList(entityId: string, params: object) {
      try {
        const { data } = await AccountsApi.getCounterpartyList(entityId, params)
        this.counterpartList = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取entity添加的CounterpartTag
     * @param {string} entityId 主体id
     */
    async fetchCounterpartTagList(entityId: string) {
      try {
        const { data } = await AccountsApi.getCounterpartTag(entityId, {})
        this.counterpartTagList = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取entity账户列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {boolean} params.withDeleted 是否包含被删除账户
     */
    async fetchEntityAccountList(entityId: string, params?: object) {
      try {
        const { data } = await AccountsApi.getEntityAccountList(entityId, params)
        this.accountList = data?.length ? flatMap(data, 'list') : []
        return this.accountList
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 编辑余额列表筛选器
     * @param {number} entityId 主体id
     * @param {object} data 过滤器数据
     */
    editAccountFilter(entityId: string, data: object) {
      const currentIndex = this.accountFilterList.findIndex((item: any) => item.entityId === entityId)
      const filterLength = Object.keys(data).length

      if (currentIndex !== -1) {
        if (!filterLength) {
          this.accountFilterList.splice(currentIndex, 1)
        } else {
          this.accountFilterList.splice(currentIndex, 1, {
            entityId,
            total: filterLength,
            data
          })
        }
      } else if (filterLength) {
        this.accountFilterList.push({ entityId, total: filterLength, data })
      }
    },
    /**
     * 编辑衍生品列表筛选器
     * @param {number} entityId 主体id
     * @param {object} data 过滤器数据
     */
    editDerivativeFilterList(entityId: string, data: object) {
      const currentIndex = this.derivativeFilterList.findIndex((item: any) => item.entityId === entityId)
      const filterLength = Object.keys(data).length

      if (currentIndex !== -1) {
        if (!filterLength) {
          this.derivativeFilterList.splice(currentIndex, 1)
        } else {
          this.derivativeFilterList.splice(currentIndex, 1, {
            entityId,
            total: filterLength,
            data
          })
        }
      } else if (filterLength) {
        this.derivativeFilterList.push({ entityId, total: filterLength, data })
      }
    }
  },
  getters: {},
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: ['accountFilterList', 'derivativeFilterList']
      }
    ]
  }
})
